<div id="sidebar" class="layout-sidebar mobile hidden" [ngStyle]="{'overflow':app.sidebarActive ? 'hidden' : 'visible'}" (click)="app.onSidebarClick($event)">
    <div class="layout-tabmenu"style="box-shadow: 0px 1px 7px rgb(0 0 0 / 10%), 0px 4px 5px -2px rgb(0 0 0 / 12%), 0px 10px 15px -5px rgb(0 0 0 / 20%) !important;">
        <a class="menu-button" >
            <button pButton pRipple type="button" icon="menu-button fas fa-times" class="p-button-rounded p-button-secondary p-button-text" (click)="removeMenu()"></button>
        </a>
        <!-- inicio de los iconos de sidebar -->
        <ul class="layout-tabmenu-nav">
            <li *ngFor="let tab of tabs" [ngClass]="{'active-item':app.activeTabIndex === tab.id}">
                <a href="#" class="ripplelink tabmenuitem-link" (click)="selectedTabClick($event, tab)"> <i
                        class="{{tab.icon}}"></i></a>
                <div class="layout-tabmenu-tooltip">
                    <div class="layout-tabmenu-tooltip-arrow"></div>
                    <div class="layout-tabmenu-tooltip-text">SIDEBAR.{{tab.id}}</div>
                </div>
            </li>
            <ng-container *ngIf="sidebarType === 'USER'">
                <li *ngFor="let item of companies">
                    <a [routerLink]="['/home/company/'+item.id+'/list-hub']" [pTooltip]="item.businessName">
                        <p-avatar styleClass="avatar-sidebar" [image]="item.logo" ></p-avatar>
                    </a>
                </li>
            </ng-container>
        </ul>

        <!-- inicio del contenido de sidebar -->
        <div class="layout-tabmenu-contents">
            <!-- Contenedor -->

            <div *ngIf="selectedTab" class="layout-tabmenu-content" [ngClass]="{'layout-tabmenu-content-active': app.activeTabIndex === selectedTab.id}">
                <div class="layout-submenu-title clearfix">
                    <span>{{selectedTab.id | translate}}</span>
                </div>
                <div style="height: calc(100vh - 90px); overflow: auto;">

                    <!-- Subtitulo y contenido de elemento sidebar -->

                    <ng-container *ngFor="let content of bodies">
                        <div *ngIf="content.idTab === selectedTab.id" class="projects-tab">
                            <!-- Contenedor -->
                            <ul>
                                <!-- Lista de subtitulos -->
                                <li class="clearfix">
                                    <ng-container *ngIf="content.enabled">
                                        <div class="content-top">
                                            <ng-container *ngIf="sidebarType === 'USER'">
                                                <a class="nav-side-a" routerLink="/home/{{content.href}}" routerLinkActive="sec-active" [routerLinkActiveOptions]="{ exact: false }">
                                                    <i class="{{content.icon}}" id="icon{{content.order}}"></i>
                                                    <span style="float: unset" class="project-title text-enabled" translate>SIDEBAR.{{content.id}}</span>
                                                </a>
                                            </ng-container>
                                            <ng-container *ngIf="sidebarType === 'COMPANY'">
                                                <a class="nav-side-a" routerLink="/home" routerLinkActive="sec-active" [routerLinkActiveOptions]="{exact: true}" *ngIf="content.id === 'HOME'">
                                                    <i class="{{content.icon}}" id="icon{{content.order}}"></i>
                                                    <span style="float: unset" class="project-title text-enabled" translate>SIDEBAR.{{content.id}}</span>
                                                </a>
                                                <a class="nav-side-a" routerLink="/home/company/{{idCompany}}/{{content.href}}" routerLinkActive="sec-active" [routerLinkActiveOptions]="{exact: false}" *ngIf="content.id != 'HOME'">
                                                    <i class="{{content.icon}}" id="icon{{content.order}}"></i>
                                                    <span style="float: unset" class="project-title text-enabled" translate>SIDEBAR.{{content.id}}</span>
                                                </a>
                                            </ng-container>
                                            <ng-container *ngIf="sidebarType === 'HUB'">
                                                <a class="nav-side-a" routerLink="/home" routerLinkActive="sec-active" [routerLinkActiveOptions]="{exact: true}" *ngIf="content.id === 'HOME'">
                                                    <i class="{{content.icon}}" id="icon{{content.order}}"></i>
                                                    <span style="float: unset" class="project-title text-enabled" translate>SIDEBAR.{{content.id}}</span>
                                                </a>
                                                <a class="nav-side-a" routerLink="/home/hub/{{idHub}}/{{idCompany}}/{{content.href}}" routerLinkActive="sec-active" [routerLinkActiveOptions]="{exact: false}" *ngIf="content.id != 'HOME'">
                                                    <i class="{{content.icon}}" id="icon{{content.order}}"></i>
                                                    <span style="float: unset" class="project-title text-enabled" translate>SIDEBAR.{{content.id}}</span>
                                                </a>
                                            </ng-container>
                                        </div>
                                    </ng-container>
                                    <ng-container *ngIf="!content.enabled">
                                        <div class="content-top">
                                            <span class="nav-side-a flex">
                                                <i class="{{content.icon}}" id="icon{{content.order}}"></i>
                                                <span style="float: unset" class="project-title text-disabled" translate>SIDEBAR.{{content.id}}</span>
                                            </span>
                                        </div>
                                    </ng-container>
                                    <div class="container-nav" *ngFor="let branch of content['subSections']">
                                        <!-- <span *ngIf="!branch.enabled" class="message">{{branch.id}}</span> -->
                                        <ng-container *ngIf="sidebarType === 'USER'">
                                            <a *ngIf="branch.enabled" class="nav-side-a" routerLink="/home/{{branch.href}}" routerLinkActive="sec-active" [routerLinkActiveOptions]="{exact: false}">
                                                <span class="message nav-side-text" translate>SIDEBAR.{{branch.id}}</span>
                                            </a>
                                        </ng-container>
                                        <ng-container *ngIf="sidebarType === 'COMPANY'">
                                            <a *ngIf="branch.enabled" class="nav-side-a" routerLink="/home/company/{{idCompany}}/{{branch.href}}" routerLinkActive="sec-active" [routerLinkActiveOptions]="{exact: false}">
                                                <span class="message nav-side-text" translate>SIDEBAR.{{branch.id}}</span>
                                            </a>
                                        </ng-container>
                                        <ng-container *ngIf="sidebarType === 'HUB'">
                                            <a *ngIf="branch.enabled" class="nav-side-a" routerLink="/home/hub/{{idHub}}/{{idCompany}}/{{branch.href}}" routerLinkActive="sec-active" [routerLinkActiveOptions]="{exact: false}">
                                                <span class="message nav-side-text" translate>SIDEBAR.{{branch.id}}</span>
                                            </a>
                                        </ng-container>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </ng-container>
                </div>

            </div>
        </div>
    </div>
</div>

<div id="sidebar" class="layout-sidebar desktop" [ngStyle]="{'overflow':app.sidebarActive ? 'hidden' : 'visible'}" (click)="app.onSidebarClick($event)">
    <div class="layout-tabmenu relative">
        <a class="menu-button relative" routerLink="/home" >
            <img src="assets/icons/CBN.png" class="morpheus-logo"/>
        </a>
        <span class="absolute version">v4.8.6</span>
        <!-- inicio de los iconos de sidebar -->
        <ul class="layout-tabmenu-nav">
            <li *ngFor="let tab of tabs" [ngClass]="{'active-item':app.activeTabIndex === tab.id}">
                <a href="#" class="ripplelink tabmenuitem-link" (click)="selectedTabClick($event, tab)"> <i
                        class="{{tab.icon}}"></i></a>
                <div class="layout-tabmenu-tooltip">
                    <div class="layout-tabmenu-tooltip-arrow"></div>
                    <div class="layout-tabmenu-tooltip-text">SIDEBAR.{{tab.id}}</div>
                </div>
            </li>
            <ng-container *ngIf="sidebarType === 'USER'">
                <li *ngFor="let item of companies">
                    <a [routerLink]="['/home/company/'+item.id+'/list-hub']" [pTooltip]="item.businessName">
                        <p-avatar styleClass="avatar-sidebar" [image]="item.logo" ></p-avatar>
                    </a>
                </li>
            </ng-container>
        </ul>

        <!-- inicio del contenido de sidebar -->
        <div class="layout-tabmenu-contents">
            <!-- Contenedor -->

            <div *ngIf="selectedTab" class="layout-tabmenu-content" [ngClass]="{'layout-tabmenu-content-active': app.activeTabIndex === selectedTab.id}">
                <div class="layout-submenu-title clearfix">
                    <span>{{selectedTab.id | translate}}</span>
                </div>
                <div style="height: calc(100vh - 90px); overflow: auto;">

                    <!-- Subtitulo y contenido de elemento sidebar -->

                    <ng-container *ngFor="let content of bodies">
                        <div *ngIf="content.idTab === selectedTab.id" class="projects-tab">
                            <!-- Contenedor -->
                            <ul>
                                <!-- Lista de subtitulos -->
                                <li class="clearfix">
                                    <ng-container *ngIf="content.enabled">
                                        <div class="content-top">
                                            <ng-container *ngIf="sidebarType === 'USER'">
                                                <a class="nav-side-a" routerLink="/home/{{content.href}}" routerLinkActive="sec-active" [routerLinkActiveOptions]="{ exact: true }">
                                                    <i class="{{content.icon}}" id="icon{{content.order}}"></i>
                                                    <span style="float: unset" class="project-title text-enabled" translate>SIDEBAR.{{content.id}}</span>
                                                </a>
                                            </ng-container>
                                            <ng-container *ngIf="sidebarType === 'COMPANY'">
                                                <a class="nav-side-a" routerLink="/home" routerLinkActive="sec-active" [routerLinkActiveOptions]="{exact: true}" *ngIf="content.id === 'HOME'">
                                                    <i class="{{content.icon}}" id="icon{{content.order}}"></i>
                                                    <span style="float: unset" class="project-title text-enabled" translate>SIDEBAR.{{content.id}}</span>
                                                </a>
                                                <a class="nav-side-a" routerLink="/home/company/{{idCompany}}/{{content.href}}" routerLinkActive="sec-active" [routerLinkActiveOptions]="{exact: false}" *ngIf="content.id != 'HOME'">
                                                    <i class="{{content.icon}}" id="icon{{content.order}}"></i>
                                                    <span style="float: unset" class="project-title text-enabled" translate>SIDEBAR.{{content.id}}</span>
                                                </a>
                                            </ng-container>
                                            <ng-container *ngIf="sidebarType === 'HUB'">
                                                <a class="nav-side-a" routerLink="/home" routerLinkActive="sec-active" [routerLinkActiveOptions]="{exact: true}" *ngIf="content.id === 'HOME'">
                                                    <i class="{{content.icon}}" id="icon{{content.order}}"></i>
                                                    <span style="float: unset" class="project-title text-enabled" translate>SIDEBAR.{{content.id}}</span>
                                                </a>
                                                <a class="nav-side-a" routerLink="/home/hub/{{idHub}}/{{idCompany}}/{{content.href}}" routerLinkActive="sec-active" [routerLinkActiveOptions]="{exact: false}" *ngIf="content.id != 'HOME'">
                                                    <i class="{{content.icon}}" id="icon{{content.order}}"></i>
                                                    <span style="float: unset" class="project-title text-enabled" translate>SIDEBAR.{{content.id}}</span>
                                                </a>
                                            </ng-container>
                                        </div>
                                    </ng-container>
                                    <ng-container *ngIf="!content.enabled">
                                        <div class="content-top">
                                            <span class="nav-side-a flex">
                                                <i class="{{content.icon}}" id="icon{{content.order}}"></i>
                                                <span style="float: unset" class="project-title text-disabled" translate>SIDEBAR.{{content.id}}</span>
                                            </span>
                                        </div>
                                    </ng-container>
                                    <div class="container-nav" *ngFor="let branch of content['subSections']">
                                        <!-- <span *ngIf="!branch.enabled" class="message">{{branch.id}}</span> -->
                                        <ng-container *ngIf="sidebarType === 'USER'">
                                            <a *ngIf="branch.enabled" class="nav-side-a" routerLink="/home/{{branch.href}}" routerLinkActive="sec-active" [routerLinkActiveOptions]="{exact: false}">
                                                <span class="message nav-side-text" translate>SIDEBAR.{{branch.id}}</span>
                                            </a>
                                        </ng-container>
                                        <ng-container *ngIf="sidebarType === 'COMPANY'">
                                            <a *ngIf="branch.enabled" class="nav-side-a" routerLink="/home/company/{{idCompany}}/{{branch.href}}" routerLinkActive="sec-active" [routerLinkActiveOptions]="{exact: false}">
                                                <span class="message nav-side-text" translate>SIDEBAR.{{branch.id}}</span>
                                            </a>
                                        </ng-container>
                                        <ng-container *ngIf="sidebarType === 'HUB'">
                                            <a *ngIf="branch.enabled" class="nav-side-a" routerLink="/home/hub/{{idHub}}/{{idCompany}}/{{branch.href}}" routerLinkActive="sec-active" [routerLinkActiveOptions]="{exact: false}">
                                                <span class="message nav-side-text" translate>SIDEBAR.{{branch.id}}</span>
                                            </a>
                                        </ng-container>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </ng-container>
                </div>

            </div>
        </div>
    </div>
</div>