import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
// import { ChatComponent } from './pages/home/chat/chat.component';
import { CompanyComponent } from './pages/home/company/company.component';
import { HomeComponent } from './pages/home/home.component';
// import { HowDoesItWorkComponent } from './pages/home/how-does-it-work/how-does-it-work.component';
import { HubComponent } from './pages/home/hub/hub.component';
import { NoPageFoundComponent } from './pages/no-page-found/no-page-found.component';
import { CompanyGuard } from './services/guards/company.guard';
import { HubGuard } from './services/guards/hub.guard';
import { SessionGuard } from './services/guards/session.guard';
import { BilledXmlComponent } from './pages/external/billed-xml/billed-xml.component';
import { ExampleComponent } from './pages/home/example/example.component';

const routes: Routes = [
  { 
    path: '', 
    loadChildren: () => import('./modules/signin/signin.module').then(m => m.SigninModule)
  },
  {
    path: 'home',
    component: HomeComponent,
    canActivate: [ SessionGuard ],
    children : [
      {
        path: '',
        loadChildren: () => import('./modules/home/red-cbn/red-cbn.module').then(m => m.RedCbnModule)
      },
      {
        path: 'opportunities',
        loadChildren: () => import('./modules/home/opportunities/opportunities.module').then(m => m.OpportunitiesModule)
      },
      {
        path: 'company/:company',
        component: CompanyComponent,
        canActivate: [ CompanyGuard ],
        children : [
          {
            path: '',
            loadChildren: () => import('./modules/home/company/company.module').then(m => m.CompanyModule)
          },
          {
            path: 'company-users',
            loadChildren: () => import('./modules/home/company-users/company-users.module').then(m => m.CompanyUsersModule)
          },
          {
            path: 'supplier-management',
            loadChildren: () => import('./modules/home/supplier-panel/supplier-panel.module').then(m => m.SupplierPanelModule)
          },
          {
            path: 'list-hub',
            loadChildren: () => import('./modules/home/list-hub/list-hub.module').then(m => m.ListHubModule)
          },
          {
            path: 'store',
            loadChildren: () => import('./modules/home/store/store.module').then(m => m.StoreModule)
          },
          {
            path: 'business-settings',
            loadChildren: () => import('./modules/home/setting-company/setting-company.module').then(m => m.SettingCompanyModule)
          },
          {
            path: 'shopping-plan',
            loadChildren: () => import('./modules/home/shopping-plan-module/shopping-plan-module.module').then(m => m.ShoppingPlanModuleModule)
          },
          {
            path: 'policies',
            loadChildren: () => import('./modules/home/policies/policies.module').then(m => m.PoliciesModule)
          },
          {
            path: 'my-account',
            loadChildren: () => import('./modules/home/company-my-account/company-my-account.module').then(m => m.CompanyMyAccountModule)
          },
          {
            path: 'approvals',
            loadChildren: () => import('./modules/home/approvals/approvals.module').then(m => m.ApprovalsModule)
          },
          {
            path: 'approvals-technical',
            loadChildren: () => import('./modules/home/approvals-technical/approvals-technical.module').then(m => m.ApprovalsTechnicalModule)
          },
          {
            path: 'approvals-economic',
            loadChildren: () => import('./modules/home/approvals-economic/approvals-economic.module').then(m => m.ApprovalsEconomicModule)
          },
          {
            path: 'approvals-requirements',
            loadChildren: () => import('./modules/home/approvals-requirements/approvals-requirements.module').then(m => m.ApprovalsRequirementsModule)
          },
          {
            path: 'comparative',
            loadChildren: () => import('./modules/home/comparative/comparative.module').then(m => m.ComparativeModule)
          },
          {
            path: 'comparative-reading-mode',
            loadChildren: () => import('./modules/home/comparative-reading-mode/comparative-reading-mode.module').then(m => m.ComparativeReadingModeModule)
          },
          {
            path: 'auction',
            loadChildren: () => import('./modules/home/auction-supplier/auction-supplier.module').then(m => m.AuctionSupplierModule)
          },
          {
            path: 'personality-requests',
            loadChildren: () => import('./modules/home/personality-requests/personality-requests.module').then(m => m.PersonalityRequestsModule)
          },
          {
            path: 'personality-requote',
            loadChildren: () => import('./modules/home/personality-requote/personality-requote.module').then(m => m.PersonalityRequoteModule)
          },
          {
            path: 'prequalification',
            loadChildren: () => import('./modules/home/personality-prequalification/personality-prequalification.module').then(m => m.PersonalityPrequalificationModule)
          },
          {
            path: 'requirement-management',
            loadChildren: () => import('./modules/home/requests-requirements-panel/requests-requirements-panel.module').then(m => m.RequestsRequirementsPanelModule)
          },
          {
            path: 'panel-quotes',
            loadChildren: () => import('./modules/home/quotes-panel/quotes-panel.module').then(m => m.QuotesPanelModule)
          },
          {
            path: 'purchase-order',
            loadChildren: () => import('./modules/home/purchase-order/purchase-order.module').then(m => m.PurchaseOrderModule)
          },
          {
            path: 'purchase-order-supplier',
            loadChildren: () => import('./modules/home/purchase-order-supplier/purchase-order-supplier.module').then(m => m.PurchaseOrderSupplierModule)
          },
          {
            path: 'conformities',
            loadChildren: () => import('./modules/home/conformities/conformities.module').then(m => m.ConformitiesModule)
          },
          {
            path: 'invoices',
            loadChildren: () => import('./modules/home/invoices/invoices.module').then(m => m.InvoicesModule)
          },
          {
            path: 'invoices-received',
            loadChildren: () => import('./modules/home/invoices-received/invoices-received.module').then(m => m.InvoicesReceivedModule)
          },
          {
            path: 'dashboard',
            loadChildren: () => import('./modules/home/dashboard/dashboard.module').then(m => m.DashboardModule)
          },
          {
            path: 'evaluations',
            loadChildren: () => import('./modules/home/evaluations/evaluations.module').then(m => m.EvaluationsModule)
          },
          {
            path: 'process-tree',
            loadChildren: () => import('./modules/home/dashboard-process-stages/dashboard-process-stages.module').then(m => m.DashboardProcessStagesModule)
          },
          {
            path: 'evaluations-management',
            loadChildren: () => import('./modules/home/evaluations-management/evaluations-management.module').then(m => m.EvaluationsManagementModule)
          },
          {
            path: 'messages',
            loadChildren: () => import('./modules/home/chat-company/chat-company.module').then(m => m.ChatCompanyModule)
          },
          {
            path: 'integration',
            loadChildren: () => import('./modules/home/integration/integration.module').then(m => m.IntegrationModule)
          },
          { 
            path: 'segment', 
            loadChildren: () => import('./modules/home/red-all-segments/red-all-segments.module').then(m => m.RedAllSegmentsModule)
          },
          {
            path: 'process-summary',
            loadChildren: () => import('./modules/home/process-summary/process-summary.module').then(m => m.ProcessSummaryModule)
          },
          {
            path: 'technical-compliance',
            loadChildren: () => import('./modules/home/technical-compliance-of-companies/technical-compliance-of-companies.module').then(m => m.TechnicalComplianceOfCompaniesModule)
          },
          {
            path: 'dashboard-template',
            loadChildren: () => import('./modules/home/dashboard-template/dashboard-template.module').then(m => m.DashboardTemplateModule)
          },
          {
            path: 'contracts',
            loadChildren: () => import('./modules/home/contracts/contracts.module').then(m => m.ContractsModule)
          },
          {
            path: 'custom-link',
            loadChildren: () => import('./modules/home/preference-link-register/preference-link-register.module').then(m => m.PreferenceLinkRegisterModule)
          },
          {
            path: 'management-queries-visits',
            loadChildren: () => import('./modules/home/management-of-queries-and-visits/management-of-queries-and-visits.module').then(m => m.ManagementOfQueriesAndVisitsModule)
          },
          {
            path: 'feedback',
            loadChildren: () => import('./modules/home/feedback/feedback.module').then(m => m.FeedbackModule)
          },
          {
            path: 'customer-panel',
            loadChildren: () => import('./modules/home/customer-panel/customer-panel.module').then(m => m.CustomerPanelModule)
          },
          {
            path: 'custom-link-new',
            loadChildren: () => import('./modules/home/preference-link-registrer-new/preference-link-registrer-new.module').then(m => m.PreferenceLinkRegistrerNewModule)
          },
          {
            path: 'example',
            component: ExampleComponent,
          }
        ]
      },
      {
        path: 'hub/:hub/:company',
        component: HubComponent,
        canActivate: [ HubGuard ],
        children : [
          {
            path: '',
            loadChildren: () => import('./modules/home/hub/hub.module').then(m => m.HubModule)
          },
          {
            path: 'segment',
            loadChildren: () => import('./modules/home/segment-hub/segment-hub.module').then(m => m.SegmentHubModule)
          },
          {
            path: 'evaluations',
            loadChildren: () => import('./modules/home/evaluations-hub/evaluations-hub.module').then(m => m.EvaluationsHubModule)
          },
          { path: '**', component: NoPageFoundComponent }
        ]
      },
      {
        path: 'search',
        loadChildren: () => import('./modules/home/search/search.module').then(m => m.SearchModule)
      },
      // {
      //   path: 'chat',
      //   component: ChatComponent,
      //   children : [
      //     {
      //       path: '',
      //       loadChildren: () => import('./modules/home/chat/chat.module').then(m => m.ChatModule)
      //     }
      //   ]
      // },
      {
        path: 'updater',
        loadChildren: () => import('./modules/home/updater/updater.module').then(m => m.UpdaterModule)
      },
      {
        path: 'updater',
        loadChildren: () => import('./modules/home/updater/updater.module').then(m => m.UpdaterModule)
      },
      {
        path: 'how-does-it-work',
        loadChildren: () => import('./modules/home/how-does-it-work/how-does-it-work.module').then(m => m.HowDoesItWorkModule)
      }
    ]
  },
  { 
    path: 'contract-approval', 
    loadChildren: () => import('./modules/link-approve/link-approve.module').then(m => m.LinkApproveModule)
  },
  { 
    path: 'contract-action', 
    loadChildren: () => import('./modules/link-revoke/link-revoke.module').then(m => m.LinkRevokeModule)
  },
  { 
    path: 'required-document-format', 
    loadChildren: () => import('./modules/formatter-required/formatter-required.module').then(m => m.FormatterRequiredModule)
  },
  {
    path: 'invoice-xml',
    component: BilledXmlComponent
  },
  { path: '**', component: NoPageFoundComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
